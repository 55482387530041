import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const BannerRequest = ({ location }) => {
  const title =
    "バナー依頼時にデザイナーがマーケターに気をつけて欲しい５つのポイント"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="バナー依頼時にデザイナーがマーケターに気をつけて欲しい５つのポイント"
          pagedesc="バナー作成を依頼する際に、デザイナーがマーケターに気をつけてほしいポイント5つをご紹介します"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/banner-request.jpg"
          pagepath="/blog/banner-request"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              バナー依頼時にデザイナーがマーケターに気をつけて欲しい５つのポイント
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月16日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="机の上にあるデスクトップパソコン"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/banner-request.jpg"
                className="w-full"
              />
            </figure>
            <div>
              <p>
                「バナーをデザイナーに依頼することになったけど、どうやって依頼したらいいか分からない！」「過去に依頼した際に、スムーズな進行ができなかった」「デザイナーの人が依頼時に必要な情報ってなに？」そんな人のために、デザイナーがマーケターに依頼時に気をつけて欲しいことをまとめました。
              </p>
              <p>
                InstagramやTwitterキャンペーン、インターネット広告、LPなどバナーが必要な場面は多いですよね。必要な情報を予め整理して、ポイントを抑えた依頼をすることで、スムーズに業務を進行しましょう。
              </p>
            </div>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    1.企画の内容が固まってから依頼する
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    2.契約内容を確認する
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    3.余裕のあるスケジュールを組む
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    4.素材のデータは元データ、高画質なものを
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    5.修正内容は具体的に
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">1.企画の内容が固まってから依頼する</h2>
              <p>依頼時に必要な要件</p>
              <ul className="blog__border-list">
                <li>企画の目的</li>
                <li>何を伝えたいか</li>
                <li>どんな人に伝えたいか</li>
                <li>制作物のイメージ</li>
                <li>NG要素（あれば）</li>
                <li>データ形式</li>
                <li>画像サイズ、解像度</li>
                <li>カラーモード</li>
                <li>テキストデータ</li>
                <li>使用してほしい素材やロゴデータの用意</li>
                <li>
                  データの使用目的（使用するのはWeb上のみか。印刷する可能性がある場合は必ず伝える。）
                </li>
              </ul>
              <p>
                項目が多いように見えるかもしれませんが、実際にやってみるとそれほど難しいことはありません。
              </p>
              <p>
                内容が定まらないうちに依頼してしまうと、変更が加わり何度も修正をしなければならなくなります。修正作業は時間がかかりますし、連絡や確認の回数も増えるので、デザイナー、マーケター、双方にとって大きな負担です。イメージがあやふやなまま依頼することも同様。内容とイメージがしっかり固まってから依頼するようにしましょう。
              </p>
              <p>
                <br />
                「お任せ」もNG。デザイナーにとっては地雷ワードです。結局「なんかイメージと違う」と修正になることが多いので、面倒なお客さん認定されてしまう可能性もあります。
              </p>
              <p>
                依頼時にイメージに近い参考画像を用意すると良いでしょう。（ただし参考画像と同じようにという依頼はNGです！著作権法に抵触する可能性があります。）もし具体的なイメージがあれば、簡単で良いのでラフを書いてデザイナーに共有することをおすすめします。
              </p>
              <p>
                「こうしてほしい」という要望の他に、「これはやめてほしい」ということがあればそちらも伝えましょう。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/2021-campaign-result"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの成功の秘訣は？PARKLoT公式アカウントのキャンペーン結果を大公開！！
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id2">2.契約内容を確認する</h2>
              <p>契約時の確認事項</p>
              <ul className="blog__border-list">
                <li>納期</li>
                <li>金額</li>
                <li>制作物の使用範囲</li>
                <li>元データを提供するか</li>
                <li>著作権は譲渡するか</li>
                <li>ラフ案の数や修正回数、追加料金</li>
                <li>二次利用の有無や、その際の料金</li>
              </ul>
              <p>
                契約内容が曖昧なまま進行してしまうと、後から大きなトラブルになりかねません。契約内容やデザイナーのスキル、制作物によって料金は変わります。相場やデザイナーの希望を確認し、予算と相談しましょう。契約書や料金表をデザイナー側が用意していることもあります。
              </p>
              <p>
                <br />
                元データの提供や著作権の譲渡、二次利用をする場合は、追加料金が必要になることが多いです。デザイナーによっては修正回数に上限を設けていたり、複数のラフ案や修正に追加料金がかかることもあるので、事前にしっかり確認しましょう。
              </p>
            </div>
            <div>
              <h2 id="id3">3.余裕のあるスケジュールを組む</h2>
              <p>
                デザインを考えるのも、データを作成するのも、想像以上に時間がかかります。デザイナーが他の仕事を抱えていることも多いです。急な仕事を依頼してくるお客さんを、快く思う人はあまり居ませんよね。余裕のないスケジュールの提案は、企業としての質も疑われかねません。
              </p>
              <p>
                <br />
                十分な制作時間が取れないと、制作物のクオリティも下がってしまいます。せっかくデザイナーに依頼するのなら、余裕のあるスケジュールでクオリティの高いものを納品してもらいましょう。
              </p>
              <p>
                「このくらいパパっとできるでしょ？」「明日までによろしく」なんてもってのほかです！誰でも自分の仕事を軽く見られたらいい気はしませんよね。
              </p>
            </div>
            <div>
              <h2 id="id4">4.素材のデータは元データ、高画質なものを</h2>
              <p>
                使用してほしい素材やロゴのデータがある場合は、デザイナーに提供します。
              </p>
              <p>
                写真を使用する場合はなるべく高画質なもの、ロゴのデータは必ず元データを提供しましょう。サイズが小さかったり解像度が低いと、仕上がりが荒くなってしまう場合があります。特に印刷物に使用する場合は、Webで使用するよりも解像度の高いデータが必要になるので注意が必要です。
              </p>
              <p>
                jpgはよく使われるデータ形式ですが、保存を繰り返すと画像が劣化していく性質があります。PSDやAiデータでの提供が望ましいです。Webサイト上から保存した画像をそのまま提供したり、サイトから拾うように依頼することはやめましょう。
              </p>
              <p>
                <br />
                使ってほしい素材をインターネットから適当に拾ってくるのはNGです！画質の問題だけでなく、法律に抵触する恐れがあるので、絶対にやめてください。最悪の場合は炎上してしまいます。
              </p>
              <p>
                今は高品質なフリー素材のサイトがたくさんあります。「写真
                フリー素材」「〇〇 イラスト
                フリー素材」なのワードで検索すると色々なサイトがヒットしますよ。
              </p>
              <p>
                フリー素材でも商用利用は禁止されていたり、別途料金が必要な場合があるので、各サイトの規約に沿って利用しましょう。
              </p>
              <p>
                <br />
                バナーに使われている画像やロゴの解像度が低いと、見栄えが悪いだけでなく信頼感にも欠けます。とは言え高画質であればあるほどいいというわけでもないので、使用目的に応じて適切なサイズのデータを用意しましょう。
              </p>
            </div>
            <div>
              <h2 id="id5">5.修正内容は具体的に</h2>
              <p>
                「なんか違う。」これは修正依頼の際によく使われるワードですが、何がどう違うのか具体的な言葉がないと、デザイナーも困ってしまいます。感覚は人それぞれ、千差万別です。「もっと可愛く」「もっとカッコよく」と言っても、その基準は人によって異なります。曖昧な表現からイメージをすり合わせるのは、予想以上に時間がかかる作業です。
              </p>
              <p>
                <br />
                「ここの部分をこのくらい大きく」「この文言ををもっと目立たせたい」「もう少し明るい色にしてほしい」など、どこをどのように修正してほしいのか具体的に伝えると、お互い効率よく仕事が進められます。使ってほしい色が決まっている場合はCMYKやRGBの数値を指定しましょう。
              </p>
              <p>
                <br />
                <a href="/blog/banner-request#id1">
                  1.企画内容が固まってから依頼する
                </a>
                の段階でイメージをしっかりと固めてから依頼しておくと、この工程の手間も省きやすくなりますよ。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/maximize-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの効果を最大化させる方法 6選
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id6">まとめ</h2>
              <p>
                バナー依頼時にデザイナーがマーケターに気をつけて欲しいポイント、いかがだったでしょうか？
              </p>
              <p>
                スムーズな依頼や業務の進行は、相手と信頼関係を構築する上で大切なことです。デザイナーと良い関係が築ければ、今後の仕事の依頼もしやすくなるでしょう。時間を有効に使えるため、お互いにメリットがあります。
              </p>
              <p>
                PARKLoTではTwitterキャンペーンを簡単に開催できるツールのご提供のほか、バナーの制作も承っています。バナー制作のご依頼もぜひご検討ください。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ikejiri" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!
                    手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
                    ～
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/fan-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プロが教えるTwitterインスタントウィン・キャンペーンのやり方。売上アップに効果的なのは「ファンマーケティング」
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンバナー【実例20選】デザイナーが解説！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/2022-fan-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【2022年版】Twitter
                    企業アカウント運用のコツと注意点（ファンマーケティング）
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default BannerRequest
